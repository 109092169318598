<template>
  <section
    :class="classes"
  >
    <div class="vendor-block__wrapper ui-container">
      <h2
        :class="`vendor-block__title--margin-${titleMargin}`"
        class="vendor-block__title h h--3"
      >
        <slot name="title" />
      </h2>

      <slot name="content" />
    </div>
  </section>
</template>

<script setup lang="ts">
  const props = withDefaults(defineProps<{
    titleMargin?: 'small' | 'big';
    theme?: 'white' | 'grey';
  }>(), {
    titleMargin: 'small',
    theme: 'white'
  })

  const classes = computed(() => {
    return [
      'vendor-block',
      `vendor-block--${props.theme}`
    ]
  })
</script>

<style lang="scss">
  @use '@academica-box/utils/styles/mixins/UiMedia' as *;

  .vendor-block {
    &--grey {
      padding-top: 32px;
      padding-bottom: 32px;
      border-radius: 20px;
      background-color: var(--bg-secondary-color);

      @include media(lg) {
        max-width: 992px;
        margin-right: auto;
        margin-left: auto;
        padding-top: 56px;
        padding-bottom: 56px;
      }

      @include media(xl) {
        max-width: 1216px;
      }

      @include media(xxl) {
        max-width: 1376px;
      }

      @include media(1600) {
        max-width: 1536px;
      }
    }
  }

  .vendor-block__wrapper {
    @include media(lg) {
      display: grid;
      grid-column-gap: 32px;
      grid-template-columns: 288px 1fr;
    }

    @include media(xl) {
      grid-template-columns: 320px 1fr;
    }

    @include media(xxl) {
      grid-template-columns: 464px 1fr;
    }

    @include media(1600) {
      grid-column-gap: 64px;
    }
  }

  .vendor-block--grey .vendor-block__wrapper {
    @include media(xxl, 1599.9) {
      padding-right: 32px;
      padding-left: 32px;
    }
  }

  .vendor-block__title {
    margin-bottom: 24px;

    @include media(lg) {
      grid-row: 1/-1;
      grid-column: 1/2;
      margin-bottom: 0;
    }

    &--margin-big {
      @include media(md-max) {
        margin-bottom: 32px;
      }
    }
  }
</style>
